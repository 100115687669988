<template>
 <div style='width: 100%; height: 100%; position: relative;'>
 
    <div class='transition' :style='getControlStyle()'>
			<div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 12pt; font-weight: bold;width: 100%;'>Import Schedule 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 9pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		       
		        <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			
			    <GSelectSimple :options="stations" style='width:100%' v-model="stationId" @changed='stationChanged'/>
			    
			    <div v-if="data && data.media" 
			         @click="openScheduleView( data.media.id, new Date(dateFrom).toISOString().split('T')[0])">
			         <center>
			        
			    	<GImage :data="getImgData( data.media.logo)"/>
			      </center>
			    </div>
			    <span class='SVcontrolLabel'>From date:</span><br/><InputDatePick  @change="dateChanged" :auto="false" v-model="dateFrom"/>
			    <span class='SVcontrolLabel'>To date:</span><br/><InputDatePick  @change="dateChanged" :auto="false" v-model="dateTo"/>
				<br/>
				<div v-if="gridImported && gridImported.from">
					<button v-for="(r,ri) in reports" :key="'br'+ri" @click='runReport(r)' :title="r.info" class='button myButton'>{{r.name}}</button>
					<br/>
					
					<div v-if="reportData && reportData.id" style='font-size: 9pt;'>
						<div style='width: 200pt; white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden;'>File: {{ reportData.name}} </div>
						Type: {{ reportData.fileTypeValue}} <br/>
						Size: {{ formatNumber(reportData.size/1024)}}kB<br/>
						<button :title="'Download '+reportData.name" v-if="reportData.fileTypeValue!=='PDF'" type="button" class="button myButton"
						        :style="getAppStyle()+'font-size: 10pt;'" 
						 key="PDF_FLD_REC2"   @click.prevent="downloadUnzip(reportData)">Download <mdicon :width="20" name="download" /></button>
					</div>
				</div>
			    <div v-if="notUsed">
			    <span class='SVcontrolLabel'>Specific dates</span><br/><InputDatePickM :from="dateFrom" :to="dateTo" @change='setDates' v-model="dates"/>
				
			    <br/>
				    <button :disabled='!stationId' v-tooltip="'show all header for given date'" class='button myButton' @click="showHeader">Show header</button>
				    <button :disabled='!stationId' v-tooltip="'display the active grid'" class='button myButton' @click="openScheduleView( stationId, new Date(dateFrom).toISOString().split('T')[0])">Show grid</button>
				    <button :disabled='!dataStored.fileId' v-tooltip="'Show data of uploaded file'" class='button myButton' @click="data=dataStored">Show file</button>
 				<br/>
 				<br/>
				</div> 
			    
			    <div v-if="compareData" style='float: left;  width:100%;'>
	 				<div class="BMSection" :style='getBGStyle2()'>
				        <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER:</b></span>
				    </div>
	 				<br/>
	 				<span class='SVcontrolLabel'>Time:</span>
				    <GSelect :options="slots" style='font-size: 9pt;' v-model="selectedSlots" :multiple="true">  
					</GSelect>
					<span class='SVcontrolLabel'>Zone:</span>
				    <GSelect :options="zones" style='font-size: 9pt;' v-model="selectedZones" :multiple="true">  
					</GSelect>

				    <span class='SVcontrolLabel'>Category:</span>
				    <GSelectMSimple  :showOpener="true" :options="categories" entity='ContentCategory'  v-model="categoryIds"/>
			    
			    <div class="BMSection" :style='getBGStyle2()'>
			        <span class='BMSectionDetail dontWrap90 smallText'><b>OPTIONS:</b></span>
			    </div>
 				<br/>
			    <span style='display: inline-flex; width: 60%; border-bottom: 1pt solid #ccc; padding-top: 4pt;' v-tooltip="'if there are not enough breaks, the last break of the same breaktype will be filled'">Fill last break</span>
			    <app-switch v-model="fillLastBreak" :checked="fillLastBreak"/>
 				<br/>
			    <span style='display: inline-flex; width: 60%; border-bottom: 1pt solid #ccc; padding-top: 4pt;' v-tooltip="'target (program and breaks) can be overbooked'">Overbook target</span>
			    <app-switch v-model="overbook" :checked="overbook"/>
			    <br/>
			    <span style='display: inline-flex; width: 60%; border-bottom: 1pt solid #ccc; padding-top: 4pt;' v-tooltip="'Spots are moved as unplaced, if no fitting break is found'">Move non-fitting to clipboard</span>
			    
			    <app-switch v-model="unplaced" :checked="unplaced"/>
			    </div>
			    
			</div>
	</div>
	
    <div class='transition uploadArea' style='float: top; position: relative;' id='uploader' 
		 		@drop="dropFile($event, 0, 0, 'uploader');"
			    @dragenter.prevent="hoverLine( 'uploader')"
			    @dragleave.prevent="leaveLine( 'uploader')"		        
				@dragover.prevent="hoverLineMain( 'uploader')" >
				<div v-if="data && data.dayLines && data.type=='SCHEDULE_FILE'">
					Type: <b>day-lines (day-breaks)</b><br/>
					Data found: <b>{{ data.dayLines.length}} </b>
					<div v-if="data && data.header && data.dayLines.length"> 
						Header found: <b>{{ data.header.name}}</b> 
						<button v-if="!data.dayLines.length" class='button' style="height: 16pt;" @click="importGrid(data.header.id)">import with this header</button>
						<button v-if="!data.dayLines.length" @click="importGridCreateHeader" style="height: 16pt !important;" class='button'>import and create new header</button>
						<button @click="importDayGrid(data.header.id)" style="font-weight: bold !important; height: 16pt !important;" class='button'>import as day grid</button>
						
						<div v-if="data && data.details"> 
						Details found: <b>{{ data.details.length}} grid details</b>
						</div>
					</div>
					
					
					<div v-else>
					<b>No header found</b><br/>
					<button class='button' style="height: 16pt !important;" @click="importGrid(0)">import and create new header</button>
					</div>
					<br/>
					
					<button :disabled="!gridImported" class='button' style="height: 16pt !important;" @click="linkBreaks(data.header.id, data)">link bookings and breaks</button>
					<br/>
					ImportedFile: <b>{{data.fileName}}</b> ({{ data.meta.unifiedName}},  ID: {{ data.fileId}})
				
					<button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='$refs.editor.open("Files", data.fileId)'>
					 <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
					  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
					</svg>
					 </button>
				</div>
				<div v-if="data && data.lines && data.type=='JUST_GRID'">
					Type: <b>grid-lines (just grid-info lines)</b><br/>
					Data found: <b>{{ data.lines.length}} </b>
					<div v-if="data && data.header && data.lines.length"> 
						Header found: <b>{{ data.header.name}}</b> 
						<button v-if="data.lines.length" class='button' style="height: 16pt;" @click="importJustGrid(data.header.id)">import with this header</button>
						
					</div>
					
					
					<div v-else>
					<b>No header found</b><br/>
					<button class='button' style="height: 16pt !important;" @click="importGrid(0)">import and create new header</button>
					</div>
					<br/>
					
					<button :disabled="!gridImported" class='button' style="height: 16pt !important;" @click="linkBreaks(data.header.id, data)">link bookings and breaks</button>
					<br/>
					ImportedFile: <b>{{data.fileName}}</b> ({{ data.meta.unifiedName}},  ID: {{ data.fileId}})
				
					<button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='$refs.editor.open("Files", data.fileId)'>
					 <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
					  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
					</svg>
					 </button>
				</div>
				<!-- 
			    <div v-if="data && data.lines && data.type!=='breakGridXLSX'">
					Data found: 
					<b v-if="!data.dayLines.length">{{ data.lines.length}} lines (grids)</b>
					<b v-else>{{ data.dayLines.length}} day-lines (breaks)</b>
					<div v-if="data && data.header && data.dayLines.length"> 
						Header found: <b>{{ data.header.name}}</b> 
						<button v-if="!data.dayLines.length" class='button' style="height: 16pt;" @click="importGrid(data.header.id)">import with this header</button>
						<button v-if="!data.dayLines.length" @click="importGridCreateHeader" style="height: 16pt !important;" class='button'>import and create new header</button>
						<button @click="importDayGrid(data.header.id)" style="font-weight: bold !important; height: 16pt !important;" class='button'>import as day grid</button>
						
						<div v-if="data && data.details"> 
						Details found: <b>{{ data.details.length}} grid details</b>
						</div>
					</div>
					
					
					<div v-else>
					<b>No header found</b><br/>
					<button class='button' style="height: 16pt !important;" @click="importGrid(0)">import and create new header</button>
					</div>
					<br/>
					ImportedFile: <b>{{data.fileName}}</b> ({{ data.meta.unifiedName}},  ID: {{ data.fileId}})
				
					<button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='$refs.editor.open("Files", data.fileId)'>
					 <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
					  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
					</svg>
					 </button>
				</div>
				-->
				<div v-if="data && data.lines && data.type==='PRICES_CSV'">
					Price file: {{ data.fileName }}<br/>
					Lines: {{ data.noOfLines }}<br/>
					Duration: {{ data.priceStructure.duration }}<br/>
					Period: {{ data.priceStructure.from }}-{{ data.priceStructure.to }}<br/>
					<button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='$refs.editor.open("Files", data.fileId)'>
					  <mdicon name="pencil"/>
					 </button>
					 <button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='importPrices( data)'>
					  <mdicon name="import"/>
					 </button>
				</div>
				<div v-if="data && data.lines && data.type==='PRICING_SCHEME_CSV'">
					Price file: {{ data.fileName }}<br/>
					Lines: {{ data.noOfLines }}<br/>
					Channel: {{ data.media.shortname }}<br/>
					Period: {{ data.pricingScheme.from }}-{{ data.pricingScheme.to }}<br/>
					<button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='$refs.editor.open("Files", data.fileId)'>
					  <mdicon name="pencil"/>
					 </button>
					 <button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='importPricingScheme( data)'>
					  <mdicon name="import"/>
					 </button>
				</div>
				<div v-if="data && data.lines && data.type==='breakGridXLSX'">
					Type: <b>break-grid (annual structure-breaks)</b><br/>
					Data found: 
					<b>{{ data.noOfLines}} lines</b>
					<div v-if="data && data.header"> 
						Header found: <b>{{ data.header.name}}</b> 
						<button class='button' style="height: 16pt;" @click="importBreakGrid(data.header.id, data)">import with this header</button>
						
						<div v-if="data && data.details"> 
						Details found: <b>{{ data.details.length}} grid details</b>
						</div>
					</div>
					
					
					<div v-else>
					<b>No header found</b><br/>
					<button class='button' style="height: 16pt !important;" @click="importGrid(0)">import and create new header</button>
					</div>
					<br/>
					ImportedFile: <b>{{data.fileName}}</b> ({{ data.meta.unifiedName}},  ID: {{ data.fileId}})
				
					<button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='$refs.editor.open("Files", data.fileId)'>
					 <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
					  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
					</svg>
					 </button>
				</div>
				<div v-if="data && data.lines && data.type==='gridXML'">
					
					Data found: 
					<b>{{ data.noOfLines}} lines (breakGrid)</b>
					<div v-if="data && data.header"> 
						Header found: <b>{{ data.header.name}}</b> 
						<button class='button' style="height: 16pt;" @click="importBreakGrid(data.header.id, data)">import with this header</button>
						<button @click="importDayGridXML(data.header.id, data)" style="font-weight: bold !important; height: 16pt !important;" class='button'>import as day grid</button>
						
						<div v-if="data && data.details"> 
						Details found: <b>{{ data.details.length}} grid details</b>
						</div>
					</div>
					
					
					<div v-else>
					<b>No header found</b><br/>
					<button class='button' style="height: 16pt !important;" @click="importBreakGrid(0, data)">import and create new header</button>
					</div>
					<br/>
					ImportedFile: <b>{{data.fileName}}</b> ({{ data.meta.unifiedName}},  ID: {{ data.fileId}})
				
					<button :disabled='(! data.fileId)' class='button btn' style="height: 16pt !important;" @click='$refs.editor.open("Files", data.fileId)'>
					 <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
					  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
					</svg>
					 </button>
				</div>
				<div v-else-if="headers">
				   <div v-for="(h,hi) in headers" :key="'h'+hi" :id="'h'+hi">
					   <div @contextmenu="headerRClicked($event, h)" style='border-bottom: 1pt solid grey;'>
					     <div style='padding-left: 5pt; display: inline-flex; width: 20% !important; white-space: nowrap;'>
					        <b v-if="h.active">{{h.name}}</b>
					        <span v-else>{{h.name}}</span>
					     </div> 
					     <div style='padding-left: 5pt; display: inline-flex; width: 15% !important; white-space: nowrap;'>{{h.fromDate}} - {{h.untilDate}} </div> 
					     
					     <button v-tooltip="'This grid is active'" v-if="h.active" class='button myButton' style='background-color: #dfd;'><mdicon  name='grid'/></button> 
					     <button v-tooltip="'Activate this grid'" v-else class='button myButton'  @click="activate(h)"><mdicon name='check-bold'/></button> 
					     <button v-tooltip="'compare this grid with the active grid'" class='button myButton'  :disabled="h.active" @click="compare(h)">compare</button>
					   </div>
				   </div>
				   <div v-if=compareData style='float: left;  width:100%;'>
				   
				   <div style='float: left; width:50%; border: 1pt solid grey;'>
				     
				     <div style='border-bottom: 1pt solid grey; height: 24pt;'><b style='color: #888;'>{{compareData.header.name}} (inactive)</b>
				     <div style='float: right;'>
					     <button v-if="emptyBreaks==0"  title='show only empty breaks' @click="emptyBreaks=1"><mdicon size='18' name='battery-outline'/></button>
					     <button v-else-if="emptyBreaks==1" title='show all breaks' @click="emptyBreaks=2"><mdicon  size='18' name='battery-50'/></button>
					     <button v-else  title='show only booked breaks' @click="emptyBreaks=0"><mdicon  size='18' name='battery'/></button>
				     </div>
				     </div>
				     <div style='height: calc(100vh - 244px); overflow-y: scroll;'> 
				        
					     <div v-for="(d,di) in filter(compareData.details, emptyBreaks)" :key="'d'+di" :id="'d'+di" class="todrag" 
					          @contextmenu="headerRClicked($event, compareData.header, d)"
					          @dragstart.exact="startDragGrid($event, d, 'move')" draggable
					          @drop.exact="onDropGrid($event, d)"
					          @mouseenter="hoverLine('d'+di)" 
					          @mouseleave="leaveLine('d'+di)">
						  <span style='font-size: 9pt;'>{{printTime( d.starttime)}}</span> 
						  <div :style="'display: inline-flex; width: 2%; font-size: 8pt; background-color:'+ getGridZone( compareData, d).colour+';margin-left: 2pt; font-size: 9pt; font-weight: bold;'">{{getGridZone( compareData, d).shortname}}</div>
						  <div :style="'display: inline-flex; width: 5%; font-size: 8pt; background-color:'+ getRatecard( compareData, d.id).colour+';margin-left: 2pt; font-size: 9pt;'">P-{{d.pricegroup}}</div>
						  <div class='dontWrap'  :style="'display: inline-flex; width: 5%; font-size: 8pt; background-color:'+ compareData.categoryMap[d.id].colour+';font-size: 9pt;'">{{compareData.categoryMap[d.id].name}}</div>
						  <div class='dontWrap' style='padding-left: 5pt; font-size: 9pt; display: inline-flex; width: 45% !important;'>
						  {{d.name}}
						  </div>
						  <div class='dontWrap' style='padding-left: 5pt; display: inline-flex; width: 16% !important;'>
						  <InputWeekdays width="50%" disabled="true" fontsize='8pt' v-model="d.weekdays"/>
						  </div>
						  <span style='padding-left: 5pt; width: 7%; font-size: 9pt;'>({{d.noOfInterruptions}} br.)</span>
						  <span style='display: inline-flex; width: 5%;padding-left: 5pt; font-size: 9pt;'>{{compareData.spotsInGrid[d.id]}}</span>
						  <span style='display: inline-flex; width: 4%;padding-left: 5pt; font-size: 9pt;'>({{compareData.clipboardSpotsInGrid[d.id]}})</span>
						  </div>
					    </div>
				    </div>
				   <div style='float: left;width:50%; border: 1pt solid grey;'>
				   <div style='border-bottom: 1pt solid grey; height: 24pt;'><b>{{compareData.activeHeader.name}} (active)</b>
				     <div style='float: right;'>
					     <button v-if="emptyBreaksActive==0"  title='show only empty breaks' @click="emptyBreaksActive=1"><mdicon size='18' name='battery-outline'/></button>
					     <button v-else-if="emptyBreaksActive==1" title='show all breaks' @click="emptyBreaksActive=2"><mdicon  size='18' name='battery-50'/></button>
					     <button v-else  title='show only booked breaks' @click="emptyBreaksActive=0"><mdicon  size='18' name='battery'/></button>
				     </div>
				   </div> 
				   
				    <div style='height: calc(100vh - 244px); overflow-y: scroll;'> 
					    <div v-for="(d,di) in filter(compareData.activeDetails, emptyBreaksActive)" :key="'ad'+di" :id="'ad'+di" class="todrag" 
					         @contextmenu="headerRClicked($event, compareData.activeHeader, d)"
					         @dragstart.exact="startDragGrid($event, d, 'move')" draggable
					         @drop.exact="onDropGrid($event, d)"
					         @mouseenter="hoverLine('ad'+di)" 
					         @mouseleave="leaveLine('ad'+di)">
						  <span style='font-size: 9pt;'>{{printTime( d.starttime)}}</span> 
						  <div :style="'display: inline-flex; width: 2%; font-size: 8pt; background-color:'+ getGridZone( compareData, d).colour+';margin-left: 2pt; font-size: 9pt; font-weight: bold;'">{{getGridZone( compareData, d).shortname}}</div>
						  <div :style="'display: inline-flex; width: 5%; font-size: 8pt; background-color:'+ getRatecard( compareData, d.id).colour+';margin-left: 2pt; font-size: 9pt;'">P-{{d.pricegroup}}</div>
						  <div class='dontWrap'  :style="'display: inline-flex; width: 5%; font-size: 8pt; background-color:'+ +';font-size: 9pt;'">{{compareData.categoryMap[d.id].name}}</div>
						  <div class='dontWrap' style='padding-left: 5pt; font-size: 9pt; display: inline-flex; width: 45% !important;'>
						  {{d.name}}
						  </div>
						  <div class='dontWrap' style='padding-left: 5pt; display: inline-flex; width: 15% !important;'>
						  <InputWeekdays width="50%" disabled="true" fontsize='8pt' v-model="d.weekdays"/>
						  </div>
						  <span style='padding-left: 5pt; width: 7%; font-size: 9pt;'>({{d.noOfInterruptions}} br.)</span>
						  <span style='display: inline-flex; width: 5%;padding-left: 5pt; font-size: 9pt;'>{{compareData.spotsInGrid[d.id]}}</span>
						  <span style='display: inline-flex; width: 4%;padding-left: 5pt; font-size: 9pt;'>({{compareData.clipboardSpotsInGrid[d.id]}})</span>
						  
						  </div>
					   </div>
				     </div>
				   </div>
				</div>
				<div v-else style='padding-top: 50pt; display: block; text-align: center; vertical-align: middle; align-items: center; justify-content: center; height: 100%; width: 100; border: 2pt solid #fcd;'>
			      <span style='color: #aaa;'>drop grid file</span>
			    </div>
		 
 	</div>
 	
	<ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem v-if="contextData.header" @clicked="$refs.menu.close(); $refs.editor.open('GridHeader', contextData.header.id)" >
          Open {{contextData.header.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.detail" @clicked="$refs.menu.close(); $refs.editor.open('GridDetail', contextData.detail.id)" >
          Open {{contextData.detail.name}}
        </ContextMenuItem>
        <ContextMenuSep v-if="!contextData.detail"/>
        <ContextMenuItem v-if="!contextData.header.active && !contextData.detail" @clicked="$refs.menu.close(); activate( contextData.header)">
          Activate {{contextData.header.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="!contextData.header.active && !contextData.detail" @clicked="$refs.menu.close(); compare( contextData.header)">
          Compare
        </ContextMenuItem>
       </template>
    </ContextMenu>
	<PDFViewer name='pdfView' ref='pdfView' />
    <GFWEOpenEditor ref='editor' @update='reload'></GFWEOpenEditor>
	<GConfirm ref='confirm'/>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
 </div>
</template>	
<script>
import {HTTP, HTTPMP, gridAPI, reportAPI, opsAPI, bngAPI, fwAPI, userAPI, invAPI, setReload, showError, myLocale, formatNumber} from '@/variables.js';
import {store, restore} from '@/gridImport.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import { setGoBack } from '@/breadCrumb.js';
import {printTimeOpt, printTimeHHMM, printTime } from '@/basicTimeFN.js';
import ProgressBar from '@/components/ProgressBar';
import Switch from '@/components/Switch';
import GImage from '@/components/GImage';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuItem from '@/components/ContextMenuItem';
import RunDownAsRun from '@/components/asrun/RunDownAsRun';
import InputWeekdays from '@/components/inputElements/DisplayWeekdays';
import RunDown from '@/components/asrun/RunDown';
import GSelectMSimple from '@/components/GSelectMSimple';
import GSelectSimple from '@/components/GSelectSimple';
import GSelect from '@/components/misc/GSelect';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import InputDatePickM from '@/components/inputElements/InputDatePickM'; 
import GridBreakView from '@/components/GridBreakView';
import GConfirm from '@/components/GConfirm';
import PDFViewer from '@/components/PDFViewer';
import JQuery from "jquery";
import 'w3-css/w3.css';
var momentTZ = require('moment-timezone')
let $ = JQuery;
var timers = [];
export default {
  name: 'GFW_IMPORT_SCHEDULE',
   
  components : {
   //vSelect, 'app-switch': Switch, InputDatePick, GConfirm, GSelectSimple, RunDownAsRun, RunDown
   'app-switch': Switch, GSelect, GConfirm, GImage, ProgressBar, InputDatePick,  PDFViewer, InputDatePickM,  ContextMenu, ContextMenuItem, ContextMenuSep,InputWeekdays,GSelectMSimple,GSelectSimple
  },
  data () {
    return {
      stationId: 0,
      controlsRight: false,
	  withStation: false,
      station: {},
      stations: [],
	  reports: [],
	  user: {},
      data: {},
	  reportData: {},
      dataStored: {},
      fileId: 0,
      headers: null,
      compareData: null,
      dateFrom: null,
      dateTo: null,
      dates: [],
      emptyBreaks: 1,
      emptyBreaksActive: 1,
      selectedHeader: null,
	  gridImported: null,
      //
      slots: [],
      selectedSlots: [],
      selectedZones: [],
      zones: [],
      //
      categories: [],
      categoryIds: [],
      //
      overbook: true,
      fillLastBreak: true,
      unplaced: false,
      // ProgBar
      pbFromGridId: 0,
      pbToGridId: 0,
      pbHeaderId: 0,
      pbAction: "",
      pbData: {},
      pbTitle: "",
      showProgressBar: false,
      getBGStyle2, formatNumber,getAppStyle
      }
  },
  methods: {
	  getControlStyle() {
	    	if ( this.controlsRight )
	    	{
	    		return "float: right; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
	    	}
	    	return "float: left; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
	    },
	    getImgData(val) { return 'data:image/png;base64,' + val; },
	    openScheduleView(stationId, isoDay)
	    {
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	this.$router.replace('scheduleView?stationId='+stationId+'&day='+ isoDay);
	    },
	    getRatecard(compareData, id)
	    {
	    	if (compareData.ratecards[id])
	    	{
	    		return compareData.ratecards[id];
	    	}
	    	return {};
	    },
	    getGridZone(compareData, d)
	    {
	    	let zone = compareData.gridZones.find(p=>p.id==d.zoneId);
	    	if ( !zone)
	    	{
	    		return {}
	    	}
	    	return zone;
	    },
	    setDates(d)
	    {
	    
	    	this.dates =d;
	    },
	    filter( details, emptyBreaks)
	    {
	    	let d = details;
	    	if ( this.categoryIds && this.categoryIds.length)
	    	{
	    		d = d.filter( x => this.categoryIds.includes( this.compareData.categoryMap[x.id].id))	
	    	}
	    	if ( this.selectedSlots && this.selectedSlots.length )
	    	{
	    		d = d.filter( x => this.selectedSlots.find(p=>p.id==x.starttime))	
	    	}
	    	if ( this.selectedZones && this.selectedZones.length )
	    	{
	    		d = d.filter( x => this.selectedZones.find(p=>p.id==x.zoneId))	
	    	}
	    	
	    	if ( emptyBreaks == 1 )
	    	{
	    		return d;
	    	}
	    	if ( emptyBreaks == 0 )
	    	{
	    		return d.filter(p => !this.compareData.spotsInGrid[p.id]);
	    	}
	    	return d.filter(p => this.compareData.spotsInGrid[p.id]);

	    },
    dropFile($event, line, rowIdx, lineREF)
    {
      let that = this;
      event.preventDefault();
      event.stopPropagation();
     
      this.showDropzone = false;
      const files = event.dataTransfer.files;
      const itemArray = [...files];
      
      const item = itemArray.find((i) => true);
      this.$refs.confirm.confirm( {text: 'Load Gridfile: ' + item.name, title: "Upload Grid", button1: "Cancel", button2: "Ok"}).then(x => {
    	  this.startLoader();
    	  let check =  new Promise(function (resolve, reject) {
    		  const formData = new FormData();
	       	  
	       	  that.leaveLine(lineREF);
		      formData.append('file', item);      
		      if (item) that.upload(formData, line, rowIdx, item.name);
		      that.stopLoader();

		   });
      });
    },
    fmtTimePart( aTime) {
	   	if ( aTime < 10 ) 
	   	{
	   		return "0"+aTime;
	   	}
	   	return ""+aTime;
	},
    printTime(time)
    {
      //console.log( "scanTime("+val+") " + this.time )
      //alert("scanTime("+JSON.stringify(val)+") " + this.time)
      if ( isNaN( time ) || ! time  )
      {
      	time = 0;
      }
    	let ss = time % 60;
      let mm = ((time - ss) / 60 ) % 60;
      let hh = ((time - ss - mm * 60) / 3600 ) % 60;
      let hhVal = this.fmtTimePart(hh);
      let mmVal = this.fmtTimePart(mm);
      //let ssVal = this.fmtTimePart(ss);
      return hhVal +":"+mmVal;
     },
    startDragGrid( evt, source, what ) {
   		evt.dataTransfer.dropEffect = what
        evt.dataTransfer.effectAllowed = what
        evt.dataTransfer.setData('dndType', what)
        evt.dataTransfer.setData('sourceId', source.id)
   	},
   	onDropGrid (evt, target, moveType) 
    {
       const sourceId = evt.dataTransfer.getData('sourceId');
       let source = this.compareData.details.find(p=>p.id==sourceId);
       if ( !source )
       {
    	   source = this.compareData.activeDetails.find(p=>p.id==sourceId);
       }
       const dndType = evt.dataTransfer.getData('dndType');
       if ( ! moveType )
       {
       	  moveType = dndType;
       }
       this.move( source, target)
	   return;
	},
    async upload(data, line, rowIdx, name) {
      let uploadFile = await this.uploadFile(data, line, rowIdx, name);
    },
    showHeader()
    {
    	let that = this;
    	that.startLoader();
    	let activeDate= new Date(this.dateFrom).toISOString().split('T')[0]
    	HTTP.post( gridAPI+"/findHeader/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate)
        .then( response => 
        { 
            that.headers = response.data;
            for ( let h in that.headers )
            {
            	let hd = that.headers[h];
            	if ( hd.active )
            	{
            		that.dateTo = new Date(hd.untilDate).toISOString().split('T')[0];
            	}
            }
           
            that.data = null;
            that.stopLoader();
            that.updateCompare();
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "reading header", err); that.stopLoader();})
    },
    activate(header)
    {
    	let that = this;
    	that.startLoader();
    	let activeDate= new Date(this.dateFrom).toISOString().split('T')[0]
    	HTTP.post( gridAPI+"/activateHeader/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+header.mediaId+"/"+activeDate+"/"+header.id)
        .then( response => 
        { 
            that.headers = response.data;
            that.data = null;
            that.stopLoader();
            that.updateCompare();
            that.$forceUpdate();

      }).catch((err) => {showError( that.$toast, "reading header", err); that.stopLoader();})
    },
    move(from, to)
    {
    	this.$refs.confirm.confirm( {text: from.name + ' -> ' + to.name, title: "Move Grid", button1: "Cancel", button2: "Ok"}).then(x => {
    	this.pbFromGridId = from.id;
    	this.pbToGridId = to.id;
        this.pbAction = "moveInternal";
        this.pbTitle = "move spots";
 	    this.showProgressBar = true;
    	});
    },
    showBreak( data)
    {
		for ( let i in data)
		{
			for ( let j in data[i].breaks)
			{
				alert( JSON.stringify( data[i].breaks[j]));
				break;
			}
			break;
		}
	},
    
    importPrices( data)
    {
		this.$refs.confirm.confirm( {text: data.priceStructure.name, title: "Import price structur", button1: "Cancel", button2: "Ok"}).then(x => {
		this.pbData = data;
        this.pbAction = "importPricesInternal";
        this.pbTitle = "Import price structure "+ data.priceStructure.name;
 	    this.showProgressBar = true;
 	    });
    },
    importPricesInternal( myId, api)
    {
		let that = this;
    	console.log("importPrices")
    	api.put( gridAPI+"/importPrices/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+that.pbData.fileId+"/"+myId, that.pbData.priceStructure)
        .then( response => 
        {             
            that.$toast.success("price structure '"+that.pbData.name+"' imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "price structure not imported", err); that.showProgressBar = false;})
	},
	importPricingScheme( data)
    {
		this.$refs.confirm.confirm( {text: data.pricingScheme.name, title: "Import price scheme", button1: "Cancel", button2: "Ok"}).then(x => {
		this.pbData = data;
        this.pbAction = "importPricingSchemeInternal";
        this.pbTitle = "Import price scheme "+ data.pricingScheme.name;
 	    this.showProgressBar = true;
 	    });
    },
    importPricingSchemeInternal( myId, api)
    {
		let that = this;
    	console.log("importPricingScheme")
    	api.put( gridAPI+"/importPricingScheme/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+that.pbData.fileId+"/"+myId, that.pbData.pricingScheme)
        .then( response => 
        {             
            that.$toast.success("price scheme '"+that.pbData.name+"' imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "price structure not imported", err); that.showProgressBar = false;})
	},
    moveInternal(myId, api)
    {
    	let that = this;
    	let options = {overbook: this.overbook, fillLastBreak: this.fillLastBreak, unplaced: this.unplaced, dateFrom: this.dateFrom, dateTo: this.dateTo, dates: this.dates}
    	//alert( JSON.stringify(options))
    	let activeDate= new Date(this.dateFrom).toISOString().split('T')[0]
    	//alert(gridAPI+"/moveGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate+"/"+this.pbFromGridId+"/"+this.pbToGridId+"/"+myId)
    	api.post( gridAPI+"/moveGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate+"/"+this.pbFromGridId+"/"+this.pbToGridId+"/"+myId, options)
        .then( response => 
        { 
        	that.showProgressBar = false;
        	that.$forceUpdate();
        	let result = response.data;
        	//alert(JSON.stringify(result));
        	let missing = parseInt(result.spots)-parseInt(result.done)
        	if ( missing == 0 )
        	{
        		that.$toast.success("All ("+result.spots+") spots moved", 'Ok', { timeout: 1000, position: "topRight" });
        	}
        	else
        	{
        		that.$toast.warning("Only "+result.done+" of "+result.spots+" spots moved", 'Ok', { timeout: 1000, position: "topRight" });
        	}
        	that.compareData.spotsInGrid[this.pbFromGridId] = parseInt(result.placedFrom);
        	that.compareData.spotsInGrid[this.pbToGridId] = parseInt(result.placedTo);
        	that.compareData.clipboardSpotsInGrid[this.pbFromGridId] = parseInt(result.unplacedFrom);
        	that.compareData.clipboardSpotsInGrid[this.pbToGridId] = parseInt(result.unplacedTo);
            
      }).catch((err) => {showError( that.$toast, "move spots", err); that.showProgressBar = false;})
    },
    updateCompare()
    {
    	this.compareData = null;
    },
    reload()
    {
    	this.compare(this.selectedHeader);
    },
    compare(header)
    {
    	//alert("com")
    	let that = this;
    	that.startLoader();
		this.selectedHeader = header;
    	let activeDate= new Date(this.dateFrom).toISOString().split('T')[0]
    	HTTP.post( gridAPI+"/compareGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+header.mediaId+"/"+activeDate+"/"+header.id)
        .then( response => 
        { 
            that.compareData = response.data;
            //alert( JSON.stringify(that.compareData))
            that.categories = that.compareData.categories.map( p=>{return {id: p.id, label: p.name, visible: true};}); 
            that.slots = that.compareData.details.map(p=>{return {id: p.starttime, label: this.printTime( p.starttime) }})
            let slots2 = that.compareData.activeDetails.map(p=>{return {id: p.starttime, label: this.printTime( p.starttime) }})
            for ( let s in slots2 )
            {
            	let slot = slots2[s];
            	if ( !that.slots.find(p=>p.id==slot.id))
            	{
            		that.slots.push( slot);
            	}
            }
            that.slots.sort(function(a,b) { return a.id-b.id});
            that.zones = that.compareData.gridZones.map( p=>{return {id: p.id, label: p.name, visible: true};}); 
            that.data = null;
            that.stopLoader();
            //alert( JSON.stringify(that.compareData))
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "compare header", err); that.stopLoader();})
    },
    uploadFile(formData, line, rowIdx, name) 
    {
      let that=this;
      
	  let station = ((!that.withStation || !this.stationId || this.stationId == 'null') ? 0: this.stationId);
	  //alert( gridAPI+"/importFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+station)
      HTTPMP.put( gridAPI+"/importFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+station, formData)
	  //HTTPMP.put( gridAPI+"/importFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId, formData)
        .then( response => 
        { 
			//alert( response.data.channelName + "/"+ JSON.stringify(response.data.programLines) )
			if ( response.data.channelName && (!response.data.dayLines || !response.data.dayLines.length))
			{
				that.$toast.info("Please select media for "+response.data.channelName, 'Ok', { timeout: 3500, position: "topRight" });
				that.withStation = true;
			}
			else
			{
				that.data = response.data;
				that.withStation = false;
				this.showBreak( that.data.programLines);
				that.dataStored = that.data;
				
				that.fileId = that.data.fileId;
				if ( that.data.header )
				{
					that.dateFrom = new Date(that.data.header.fromDate).toISOString().split('T')[0];
					that.dateTo = new Date(that.data.header.untilDate).toISOString().split('T')[0];
				}
				else
				{
					that.dateFrom = new Date(that.data.meta.from).toISOString().split('T')[0];
					that.dateTo = new Date(that.data.meta.to).toISOString().split('T')[0];
				}
				if ( that.data.media )
				{
					that.stationId = that.data.media.id;
				}
	            that.stopLoader();
	            that.$toast.success("File '"+name+"' uploaded'", 'Ok', { timeout: 1500, position: "topRight" });
				this.showBreak( that.data.programLines);
	            that.$forceUpdate();
			}
      }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.stopLoader();})

    },
    action( what, myId, api)
    {
    	console.log(what+"/"+ myId+"/"+api)
   		this[what](myId, api)
    },
    importGrid( headerId)
    {
    	this.pbHeaderId = headerId;
        this.pbAction = "importGridInternal";
        this.pbTitle = "Import grid";
 	    this.showProgressBar = true;
    },
	importJustGrid( headerId)
    {
    	this.pbHeaderId = headerId;
        this.pbAction = "importJustGridInternal";
        this.pbTitle = "Import grid";
 	    this.showProgressBar = true;
    },
    importBreakGrid( headerId, data)
    {
		this.pbHeaderId = headerId;
    	this.pbData = data;
        this.pbAction = "importBreakGridInternal";
        this.pbTitle = "Import break grid";
 	    this.showProgressBar = true;

    },
    importDayGridXML( headerId, data)
    {
    	this.pbHeaderId = headerId;
    	this.pbData = data;
        this.pbAction = "importDayGridXMLInternal";
        this.pbTitle = "Import daily break grid";
 	    this.showProgressBar = true;

    },
	linkBreaks( headerId, data)
	  {
	    	this.pbHeaderId = headerId;
	    	this.pbData = data;
	        this.pbAction = "linkBreaksInternal";
	        this.pbTitle = "Link grid breaks to breaks";
	 	    this.showProgressBar = true;

	 },
    
    importGridCreateHeader( headerId)
    {
    	this.pbHeaderId = 0;
        this.pbAction = "importGridCreateInternal";
        this.pbTitle = "Import grid (with new header)";
 	    this.showProgressBar = true;
    },
    importDayGrid( headerId)
    {
    	this.pbHeaderId = headerId;
        this.pbAction = "importDayGridInternal";
        this.pbTitle = "Import as day grid";
 	    this.showProgressBar = true;
    },
    importDayGridInternal(myId, api)
    {
    	let that = this;
    	console.log("importDayGridInternal")
    	api.put( gridAPI+"/importDayGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/all/"+this.pbHeaderId+"/"+myId)
        .then( response => 
        {             
            that.$toast.success("day grid '"+name+"' imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
			that.gridImported = response.data;
			if ( that.gridImported && that.gridImported.from)
			{
				that.dateFrom = that.gridImported.from;
				that.dateTo = that.gridImported.to;
			}
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },	
	linkBreaksInternal(myId, api)
    {
    	let that = this;
    	console.log("importDayGridInternal")
    	api.put( gridAPI+"/linkBreaksToDayBreaks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+myId, that.gridImported)
        .then( response => 
        {             
            that.$toast.success("day grid linked'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },	
    importBreakGridInternal(myId, api)
    {
        
    	let that = this;
    	this.showBreak( this.pbData.programLines);
    	api.put( gridAPI+"/importBreakGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.pbHeaderId+"/"+myId, {programLines: this.pbData.programLines})
        .then( response => 
        {             
            that.$toast.success("break grid imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },
    importDayGridXMLInternal(myId, api)
    {
        
    	let that = this;
    	console.log("importBreakGridInternal")
    	api.put( gridAPI+"/importDayGridXML/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.pbHeaderId+"/"+myId, {programLines: this.pbData.programLines})
        .then( response => 
        {             
            that.$toast.success("break grid imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },
    importGridInternal(myId, api)
    {
    	let that = this;
    	
    	api.put( gridAPI+"/importGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.pbHeaderId+"/"+myId)
        .then( response => 
        {             
            that.$toast.success("Grid imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
			
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },
	importJustGridInternal(myId, api)
    {
    	let that = this;
    	
    	api.put( gridAPI+"/importJustGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.pbHeaderId+"/"+myId)
        .then( response => 
        {             
            that.$toast.success("Grid imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
			
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },
    importGridCreateInternal(myId, api)
    {
    	let that = this;
    	api.put( gridAPI+"/importGridCreateHeader/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.data.header.id+"/"+myId)
        .then( response => 
        { 
            
            that.$toast.success("Grid '"+name+"' imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid '"+name+"' not imported", err); that.showProgressBar = false;})
    },
    hoverLine(line)
    {
    	$('#'+line).addClass('reqLineBG2');
    },
    hoverLineMain(line)
    {
    	//$('#'+line).addClass('reqLineBG');
    },
    leaveLine(line)
    {
    	$('#'+line).removeClass('reqLineBG2');
    },
	runReport( report) 
	{
		this.pbAction = "runReportInternal";
    	this.pbTitle = "Run "+ report.info;
    	this.pbReport=report;
  	    this.showProgressBar = true;
	},
	// record SpecialReportRequest ( String from, String to, Long mediaId, String classname) {} 
    runReportInternal(  myId, api) {
        let that = this;
		let from = momentTZ(this.dateFrom).utcOffset(0, true).format().split('T')[0];
		let to = momentTZ(this.dateTo).utcOffset(0, true).format().split('T')[0];
        let req = { from: from, to: to, mediaId: this.stationId, classname: this.pbReport.classname};
		//alert( JSON.stringify( req));
    	let dataLoad = new Promise(function (resolve, reject) {
            api.post( reportAPI+"/runSpecialReport/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
                 	
                    that.reportData = response.data;
					//alert( JSON.stringify( that.reportData))
					that.showProgressBar = false;
                    resolve (true);                    
                 }).catch(e => {
					    that.showProgressBar = false;
                        that.$toast.error("loading data for report: " + e.response.data, 'Error', { position: "topRight" });
                        reject();
                    });
	      });
    },
	downloadUnzip( data)
    {
		
    	this.$refs.pdfView.download(data.data, data.name);
    },
    loadStation( stationId, activeDate )
    {
    	let that = this;
    	let userLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
                	 that.user = response.data;
                	 that.stationIds = that.user.media;
                	 
                	 resolve (that.stationIds);
                 }).catch(e => {
                     that.$toast.error("loading user (ImportGrid): " + e.response.data, 'Error', { position: "topRight" });
                     that.stopLoader();
                     reject ();
                 });
	      });
    	userLoad.then( stationIds => {
        	let stationLoad = new Promise(function (resolve, reject) {
        	    //alert(sessionStorage.unitId);
        	    
                console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/allEntities/true/0");
				HTTP.post( reportAPI+"/getSpecialReports/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	        		 .then( response => 
	                 {
	                 	var allStations = response.data.stations;
						allStations.sort((a,b)=>a.name.localeCompare(b.name));
						that.stations = allStations.map(a => {return {id: a.id, label: a.name, visible: true}});
	                    that.reports = response.data.reports;
						console.log( JSON.stringify( that.reports))
	                    that.selectedStation =  that.stations[0];
	                    resolve (that.stations);                    
	                 }).catch(e => {
	                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
	                        reject ();
	                    });
    	      });
        	
        });
    },
    headerRClicked(event, header, detail)
    {
    	//alert( JSON.stringify({header: header, detail: detail}));
        event.preventDefault();
        this.$refs.menu.open(event, {header: header, detail: detail} )
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	storeSettings() 
	{
		try
		{
	    	let obj = this.$data;
	    	//this.userUpdated();
	    	store( obj);
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	},
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  beforeDestroy() {
    this.storeSettings();
  },
  created() {
	if ( sessionStorage.lastMediaId )
	{
		this.stationId = sessionStorage.lastMediaId;
	}
	
	let restored = restore();
	if ( restored && restore.data )
	{
		this.data = restored.data;
		this.dataStored = restored.dataStored;
		this.gridImported = restored.gridImported;
	}
	this.withStation = false;
	this.dateFrom = new Date().toISOString().split('T')[0]
	this.dateTo = new Date().toISOString().split('T')[0]
	this.loadStation();

	initAppMode();
  },
  watch: {
   stationId: function() {
	   sessionStorage.lastMediaId = this.stationId;
   },
   selectedStates: function() {
	    
		
	},
  },
  updated() {
  		//console.log("update...");

 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.bold {
  font-weight: bold;
}
.todrag {
 display: block;
 width: 100%; 
 height: 16pt;
 border-left: 1pt solid #ccc; 
 border-bottom: 1pt solid #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  width: 100%;
  display: block;
}
.progTitle {
  white-space: normal;
  width: 100%;
  display: flex;
  border: 1pt solid grey;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
  
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.uploadArea {
 float: left; 
 width: 72%;  
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.reqLineBG {
	border: 2pt solid red !important;
}
.reqLineBG2 {
    cursor: pointer;
    color: #fff;
	background-color: #888 !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
.header {
  font-size: 14pt;
  font-weight: bold;
}

</style> 